<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <div class="form" @submit.prevent>
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">
              {{ $t("Company Information") }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">
              {{ $t("Update your company's information") }}
            </span>
          </div>
          <div class="card-toolbar">
            <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click.prevent="handleSubmit(save)">
              {{ $t("Save Changes") }}
            </button>
          </div>
        </div>
        <div class="card-body detail">
          <validation-provider v-slot="{ errors }" name="logo" vid="logo_tus">
            <avatar-input :current-avatar="legalPerson.logo" horizontal is-legal :error-messages="errors"
              :label="$t('Avatar')" @change="(tusId) => (legalPersonForm.logo_tus = tusId)"></avatar-input>
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('Business Name')" vid="name" rules="max:180|required">
            <euro-input v-model="legalPersonForm.name" :label="`${$t('Business Name')}*`" horizontal
              :error-messages="errors"></euro-input>
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('Country of establishment')" vid="country_of_establishment"
            rules="required">
            <euro-select v-model="legalPersonForm.country_of_establishment" :label="`${$t('Country of establishment')}*`"
              horizontal :error-messages="errors" :options="countriesSelect" searchable
              @input="resetAddress"></euro-select>
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('Place of establishment')" vid="place_of_establishment">
            <euro-place-input ref="autocomplete" v-model="legalPersonForm.place_of_establishment" :label="`${$t('Place of establishment')}`"
              horizontal :restriction="{ country: legalPersonForm.country_of_establishment || '' }"
              :error-messages="errors"></euro-place-input>
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('Date of establishment')" vid="date_of_establishment">
            <date-picker v-model="legalPersonForm.date_of_establishment" :overrides="fromDateOverrides" horizontal
              :error-messages="errors" :label="$t('Date of establishment')">
            </date-picker>
            <!-- <euro-date-picker
              v-model="legalPersonForm.date_of_establishment"
              label="Date of establishment"
              horizontal
              :error-messages="errors"
              :max-date="maxDate"
            ></euro-date-picker> -->
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('method of establishment')" vid="method_of_establishment">
            <euro-select v-model="legalPersonForm.method_of_establishment" :label="$t('Entity legal form(ELF)')"
              horizontal :error-messages="errors" searchable :options="methodsOfEstablishment"></euro-select>
          </validation-provider>

          <validation-provider v-slot="{ errors }" :name="$t('Website')" vid="Website" rules="url">
            <euro-input v-model="legalPersonForm.websitecompany_set[0].website" :label="$t('Website')" horizontal
              placeholder="https://www.example.com  or  www.example.com" :error-messages="errors"></euro-input>
          </validation-provider>

          <template v-if="legalPerson.country_of_establishment == 'IT'">
            <validation-provider v-slot="{ errors }" :name="$t('SDI code')" vid="sdi_code">
              <euro-input v-model="legalPersonForm.sdi_code" :label="$t('SDI code')" :error-messages="errors"
                horizontal></euro-input>
            </validation-provider>
          </template>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import CompanyDetailService from "@/core/services/company/company-detail.service";
import CompanyEstablishmentmethodService from "@/core/services/company/company-establishmentmethod.service";
import { mapState } from "vuex";
import { getValidationErrors, dangerToast, successToast } from "@/core/helpers";
import {backendErrorSwal, unsavedChangesSwal} from "@/core/helpers/swal";
import icons from "@/core/config/icons.js";
import _ from "lodash";

export default {
  components: {
    datePicker,
  },
  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      icons,
      maxDate: today,
      legalPersonForm: {
        logo: null,
        logo_tus: null,
        name: "",
        description: "",
        method_of_establishment: "",
        country_of_establishment: "",
        place_of_establishment: {},
        date_of_establishment: null,
        websitecompany_set: [{ website: "" }],
        sdi_code: this.legalPerson?.sdi_code ?? "",
      },
      apiErrors: null,
      isSaving: false,
      legalPersonKinds: [],
      methodsOfEstablishment: [],
      originalData: null,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    fromDateOverrides() {
      return this.legalPersonForm.date_of_establishment ? { maxDate: moment() } : null;
    },

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    requestBody() {
      const body = { ...this.legalPersonForm, id: this.legalPerson.id };
      if (!this.legalPersonForm.websitecompany_set[0].website) body.websitecompany_set[0].website = null;

      return body;
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;

        const newErrors = getValidationErrors(val, this.legalPersonForm);

        formObs.setErrors({ ...newErrors });
      },
    },

    "legalPersonForm.country_of_establishment": {
      deep: true,
      handler(val) {
        this.loadMethodsOfEstablishment(val);
        // console.log(val);
        // this.legalPersonForm.place_of_establishment = null;
      },
    },
    "legalPersonForm.websitecompany_set": {
      deep: true,
      handler(val) {
        if (val[0].website) {
          if (
            val[0].website.trim().length > 8 &&
            !val[0].website.trim().startsWith("http://") &&
            !val[0].website.trim().startsWith("https://")
          ) {
            this.legalPersonForm.websitecompany_set[0].website = this.formatWebsite();
          }
        }
      },
    },
  },

  async created() {
    // Emit updated so we make sure the legal person info is up to date.
    // this.$emit("updated");
    await this.init();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Company Information") },
    ]);
  },

  methods: {
    resetAddress() {
      this.legalPersonForm.place_of_establishment = null;
    },
    async save() {
      this.isSaving = true;
      try {
        await CompanyDetailService.update(this.legalPerson.id, this.requestBody);
        this.originalData = _.cloneDeep(this.legalPersonForm)
        this.$emit("updated");
        successToast(this.$t("Details saved"));
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          dangerToast(err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },

    formatWebsite() {
      const https = "https://";
      const http = "http://";
      let site = this.legalPersonForm.websitecompany_set[0];
      if (site) {
        site = site.website.trim();
        if (!site.startsWith(https) && !site.startsWith(http)) site = https + site;
      }

      return site;
    },

    cancel() {
      this.legalPersonForm = {
        logo: null,
        name: "",
        description: "",
        kind: "",
        country_of_establishment: "",
        place_of_establishment: {},
        method_of_establishment: "",
        date_of_establishment: "",
        sdi_code: "",
      };
    },

    async init() {
      try {
        const [legalPersonKinds] = await Promise.all([CompanyDetailService.getKinds()]);
        this.legalPersonKinds = legalPersonKinds.map((el) => ({ value: el.value, text: el.display_name }));
        this.legalPersonForm = {
          name: this.legalPerson?.name,
          description: this.legalPerson?.description,
          kind: this.legalPerson?.kind,
          country_of_establishment: this.legalPerson?.country_of_establishment,
          place_of_establishment: this.legalPerson?.place_of_establishment,
          method_of_establishment: this.legalPerson?.method_of_establishment,
          date_of_establishment: this.legalPerson?.date_of_establishment,
          websitecompany_set: this.legalPerson?.websitecompany_set.length
            ? this.legalPerson.websitecompany_set
            : [{ website: "" }],
          sdi_code: this.legalPerson?.sdi_code ?? "",
        };

        this.originalData = _.cloneDeep(this.legalPersonForm);
      } catch (err) {
        backendErrorSwal(err, this.$t("failed to init"));
      }
    },

    async loadMethodsOfEstablishment() {
      const r = await CompanyEstablishmentmethodService.getByCountry(this.legalPersonForm.country_of_establishment);

      if (!r.results.length) {
        this.legalPersonForm.method_of_establishment = "";
      }

      this.methodsOfEstablishment = r.results.map((el) => ({
        text: el.abbreviations ? el.abbreviations.split(";")[0] + " - " + el.name_transliterated : el.name_transliterated,
        value: el.id
      }));
    },
  },

  async beforeRouteLeave(__, ___, next) {
    if (!_.isEqual(this.legalPersonForm, this.originalData)) {
      const res = await unsavedChangesSwal();
      if (res.isConfirmed) await this.save();
      if (res.isDismissed) return;
    }
    next();
  },
};
</script>
