import api from "../api.service";

class CompanyEstablishmentMethodService {
  get PATH() {
    return "company-establishmentmethod/";
  }

  async getByCountry(country) {
    const res = await api.get(`${this.PATH}`, { params: { country } });
    return res.data;
  }
}

export default new CompanyEstablishmentMethodService();
