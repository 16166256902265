var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Company Information")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("Update your company's information")) + " ")])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "card-body detail"
        }, [_c('validation-provider', {
          attrs: {
            "name": "logo",
            "vid": "logo_tus"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('avatar-input', {
                attrs: {
                  "current-avatar": _vm.legalPerson.logo,
                  "horizontal": "",
                  "is-legal": "",
                  "error-messages": errors,
                  "label": _vm.$t('Avatar')
                },
                on: {
                  "change": function change(tusId) {
                    return _vm.legalPersonForm.logo_tus = tusId;
                  }
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Business Name'),
            "vid": "name",
            "rules": "max:180|required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Business Name'), "*"),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.legalPersonForm.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.legalPersonForm, "name", $$v);
                  },
                  expression: "legalPersonForm.name"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Country of establishment'),
            "vid": "country_of_establishment",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": "".concat(_vm.$t('Country of establishment'), "*"),
                  "horizontal": "",
                  "error-messages": errors,
                  "options": _vm.countriesSelect,
                  "searchable": ""
                },
                on: {
                  "input": _vm.resetAddress
                },
                model: {
                  value: _vm.legalPersonForm.country_of_establishment,
                  callback: function callback($$v) {
                    _vm.$set(_vm.legalPersonForm, "country_of_establishment", $$v);
                  },
                  expression: "legalPersonForm.country_of_establishment"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Place of establishment'),
            "vid": "place_of_establishment"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('euro-place-input', {
                ref: "autocomplete",
                attrs: {
                  "label": "".concat(_vm.$t('Place of establishment')),
                  "horizontal": "",
                  "restriction": {
                    country: _vm.legalPersonForm.country_of_establishment || ''
                  },
                  "error-messages": errors
                },
                model: {
                  value: _vm.legalPersonForm.place_of_establishment,
                  callback: function callback($$v) {
                    _vm.$set(_vm.legalPersonForm, "place_of_establishment", $$v);
                  },
                  expression: "legalPersonForm.place_of_establishment"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Date of establishment'),
            "vid": "date_of_establishment"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('date-picker', {
                attrs: {
                  "overrides": _vm.fromDateOverrides,
                  "horizontal": "",
                  "error-messages": errors,
                  "label": _vm.$t('Date of establishment')
                },
                model: {
                  value: _vm.legalPersonForm.date_of_establishment,
                  callback: function callback($$v) {
                    _vm.$set(_vm.legalPersonForm, "date_of_establishment", $$v);
                  },
                  expression: "legalPersonForm.date_of_establishment"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('method of establishment'),
            "vid": "method_of_establishment"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": _vm.$t('Entity legal form(ELF)'),
                  "horizontal": "",
                  "error-messages": errors,
                  "searchable": "",
                  "options": _vm.methodsOfEstablishment
                },
                model: {
                  value: _vm.legalPersonForm.method_of_establishment,
                  callback: function callback($$v) {
                    _vm.$set(_vm.legalPersonForm, "method_of_establishment", $$v);
                  },
                  expression: "legalPersonForm.method_of_establishment"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Website'),
            "vid": "Website",
            "rules": "url"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": _vm.$t('Website'),
                  "horizontal": "",
                  "placeholder": "https://www.example.com  or  www.example.com",
                  "error-messages": errors
                },
                model: {
                  value: _vm.legalPersonForm.websitecompany_set[0].website,
                  callback: function callback($$v) {
                    _vm.$set(_vm.legalPersonForm.websitecompany_set[0], "website", $$v);
                  },
                  expression: "legalPersonForm.websitecompany_set[0].website"
                }
              })];
            }
          }], null, true)
        }), _vm.legalPerson.country_of_establishment == 'IT' ? [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('SDI code'),
            "vid": "sdi_code"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": _vm.$t('SDI code'),
                  "error-messages": errors,
                  "horizontal": ""
                },
                model: {
                  value: _vm.legalPersonForm.sdi_code,
                  callback: function callback($$v) {
                    _vm.$set(_vm.legalPersonForm, "sdi_code", $$v);
                  },
                  expression: "legalPersonForm.sdi_code"
                }
              })];
            }
          }], null, true)
        })] : _vm._e()], 2)])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }