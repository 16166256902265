import api from "../api.service";
import addressService from "../address.service";
class CompanyDetailService {
  async getKinds() {
    const res = await api.options("company-details/");
    return res.data.actions.POST.kind.choices;
  }

  async getOne(companyID) {
    const res = await api.get(`company-details/${companyID}/`);
    return res.data;
  }

  async update(companyID, body) {
    const {
      place_of_establishment
    } = body;

    if (place_of_establishment) {
      const convertedAddresses = await addressService.getOrCreateAddress([
        {
          address: place_of_establishment,
          manual_address: false,
        },
      ]);

      body.place_of_establishment = convertedAddresses[0].id;
    }

    const res = await api.patch(`company-details/${companyID}/`, body);
    return res.data;
  }
}

export default new CompanyDetailService();